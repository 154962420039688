<script setup lang="ts">
import { Button, Dropdown, DropdownItem } from "@internal/ui";
import CloseLine from "vue-remix-icons/icons/ri-close-line.vue";
import MenuLine from "vue-remix-icons/icons/ri-menu-line.vue";

import { useAccountStore } from "../../stores/account";
import { useBoardStore } from "../../stores/board";
interface Props {
  openMobileMenu: boolean;
}
const props = defineProps<Props>();
const emits = defineEmits(["update:openMobileMenu"]);
const openMobileMenu = computed({
  get: () => props.openMobileMenu,
  set: (val) => emits("update:openMobileMenu", val),
});
const route = useRoute();
watch(
  () => route.path,
  () => {
    openMobileMenu.value = false;
  },
);
const router = useRouter();
const accountStore = useAccountStore();
const boardStore = useBoardStore();
const logout = async () => {
  await accountStore.logout();
};
</script>
<template>
  <header class="header">
    <div class="container">
      <picture @click="router.push({ path: '/' })" class="header-logo">
        <source
          :srcset="
            useImage()('/logo/horizontal-transparent.png', {
              format: 'webp',
              height: 344,
              width: 1414,
            })
          "
          height="60"
          media="(min-width:768px)"
          width="247"
        />
        <img
          :src="
            useImage()('/logo/icon.png', {
              format: 'webp',
              height: 256,
              width: 256,
            })
          "
          height="60"
        />
      </picture>
      <div class="header-menu">
        <Dropdown>
          <template #trigger>
            <p>학회 소개</p>
          </template>
          <DropdownItem @click="router.push({ path: '/about/map' })"
            >오시는 길</DropdownItem
          >
          <DropdownItem @click="router.push({ path: '/about/officer' })"
            >임원단</DropdownItem
          >
        </Dropdown>
        <Dropdown :key="menuIdx" v-for="(menu, menuIdx) in boardStore.menus">
          <template #trigger>
            <p>{{ menu.title }}</p>
          </template>
          <DropdownItem
            :key="boardIdx"
            @click="router.push({ path: `/board/${board.id}` })"
            v-for="(board, boardIdx) of menu.boards"
            >{{ board.title }}</DropdownItem
          >
        </Dropdown>
      </div>
      <div>
        <div class="header-buttons" v-if="accountStore.loggedIn">
          <Button
            @click="router.push({ path: '/account/info' })"
            label="내 정보"
          />
          <Button
            @click="logout"
            background-color="#f1f1f1"
            label="로그아웃"
            text-color="#F8657A"
          />
        </div>
        <div class="header-buttons" v-else>
          <Button
            @click="router.push({ path: '/register/agree' })"
            background-color="#f1f1f1"
            label="회원가입"
            text-color="#F8657A"
          />
          <Button
            @click="router.push({ path: '/account/login' })"
            label="로그인"
          />
        </div>
        <div class="header-buttons--mobile">
          <CloseLine @click="openMobileMenu = false" v-if="openMobileMenu" />
          <MenuLine @click="openMobileMenu = true" v-else> </MenuLine>
        </div>
      </div>
    </div>
  </header>
</template>
<style scoped lang="scss">
.header {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e3e3e3;
  font-size: 16px;
  .container {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-logo:hover {
    cursor: pointer;
  }
  .header-menu {
    display: flex;
    flex-direction: row;
    > *:not(:first-child) {
      margin-left: 32px;
    }
  }
  .header-buttons--mobile {
    display: none;
  }
  .header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    > *:not(:first-child) {
      margin-left: 16px;
    }
  }
}

.header-buttons--mobile {
  width: 24px;
  height: 24px;
}
@include mobile {
  .header {
    .container {
      padding-left: 24px;
      padding-right: 24px;
      .header-buttons,
      .header-menu {
        display: none;
      }
      .header-buttons--mobile {
        display: block;
      }
    }
  }
}
</style>
