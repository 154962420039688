<script setup lang="ts">
import { useAccountStore } from "../../stores/account";
import { useBoardStore } from "../../stores/board";
const accountStore = useAccountStore();
const boardStore = useBoardStore();
const logout = async () => {
  await accountStore.logout();
};
</script>
<template>
  <div class="mobile-menu">
    <div v-if="!!accountStore.me.name">
      <h3 class="mb-0">{{ accountStore.me.name }}</h3>
      <p>{{ accountStore.me.generation }}기</p>
    </div>
    <div class="menu">
      <p class="menu-item mt-2">학회 소개</p>
      <NuxtLink
        :to="{ path: '/about/map' }"
        class="menu-sub-item menu--clickable"
        >오시는 길</NuxtLink
      >
      <NuxtLink
        :to="{ path: '/about/officer' }"
        class="menu-sub-item menu--clickable"
        >임원단</NuxtLink
      >
      <div :key="menuIdx" v-for="(menu, menuIdx) in boardStore.menus">
        <p class="menu-item">{{ menu.title }}</p>
        <NuxtLink
          :key="boardIdx"
          :to="{ path: `/board/${board.id}` }"
          class="menu-item menu-sub-item menu--clickable"
          v-for="(board, boardIdx) of menu.boards"
        >
          {{ board.title }}
        </NuxtLink>
      </div>
    </div>
    <div class="mobile-menu-footer">
      <div class="footer-menu" v-if="accountStore.loggedIn">
        <NuxtLink :to="{ path: '/account/info' }" class="footer-item"
          >내 정보</NuxtLink
        >
        <div class="horizontal-divider"></div>
        <span @click="logout" class="footer-item">로그아웃</span>
      </div>
      <div class="footer-menu" v-else>
        <NuxtLink :to="{ path: '/register/agree' }" class="footer-item"
          >회원가입</NuxtLink
        >
        <div class="horizontal-divider"></div>
        <NuxtLink :to="{ path: '/account/login' }" class="footer-item"
          >로그인</NuxtLink
        >
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.horizontal-divider {
  width: 1px;
  height: 18px;
  background-color: #e3e3e3;
  margin-left: 8px;
  margin-right: 8px;
  display: inline-block;
}
.mobile-menu {
  width: 100%;
  height: 100%;
  padding: 16px 32px 16px 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  &-footer {
    display: flex;
    justify-content: center;
    padding: 0px 16px 2rem 16px;
    .footer-menu {
      display: flex;
      justify-content: center;
      align-content: center;
    }
  }
  .menu {
    flex: 1;
  }
  .menu-item,
  .menu-sub-item {
    padding: 8px;
    display: block;
  }
  .footer-item:link,
  .footer-item:visited,
  .menu-item:link,
  .menu-item:visited,
  .menu-sub-item:link,
  .menu-sub-item:visited {
    color: black;
  }
  .menu-sub-item {
    margin-left: 8px;
    padding-left: 20px;
    border-left: 1px solid #e3e3e3;
  }
  .menu--clickable::after {
    content: ">";
    color: #7d7d7d;
    float: right;
  }
}
</style>
