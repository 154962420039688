<script lang="ts" setup>
const openMobileMenu = ref(false);
</script>
<template>
  <div id="app">
    <DefaultHeader v-model:open-mobile-menu="openMobileMenu" />
    <MobileMenu v-if="openMobileMenu" />
    <main v-show="!openMobileMenu">
      <slot />
    </main>
    <DefaultFooter v-show="!openMobileMenu" />
  </div>
</template>

<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  > main {
    flex: 1;
  }
}
</style>
